import React, { useEffect } from "react";
import { TemplatesCoreProvider, TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { templatesComponentsMap } from "@newageerp/v3.templates.templates-components-map"
import { MainBundle,  } from "@newageerp/v3.app.main-bundle";
import '@newageerp/v3.app.main-bundle/dist/main-bundle.css'

import { PluginsMap } from "../../../Plugins/PluginsMap"

function App() {
    useEffect(() => {
        window.document.title = `Loading...`;
    }, []);

    return (
        <MainBundle>
            <TemplatesCoreProvider
                resolvers={{}}
                templatesMap={templatesComponentsMap}
                pluginsMap={PluginsMap}
                
                modules={{
                    
                    properties: {
                       
                    },
                    
                }}
                dataCache={{
                    cacheData: (userState: any) => ([]),
                    fields: {},
                }}
            >
                <TemplatesLoader templateName="App" />
            </TemplatesCoreProvider>
        </MainBundle>
    );
}

export default App;
