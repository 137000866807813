import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle'
import { MenuItem } from '@newageerp/v3.bundles.modal-bundle'
import React, { Fragment, useState } from 'react'
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths'
import { Popup } from '@newageerp/v3.bundles.popup-bundle';

export default function UsersMenuItem(props: {id: number}) {
    const [doReq, doReqParams] = useURequestRaw<any>(NaePathsMap.post.PluginsAuthUserGenToken);
    const [showPopup, setShowPopup] = useState(false);

    const onClick = () => {
        doReq({
            id: props.id
        }).then(() => {
            setShowPopup(true);
        })
    }

    return (
        <Fragment>
            <Popup title="Link" isPopup={showPopup} onClick={() => setShowPopup(false)}>
                {!!doReqParams.data && <p className='max-w-[250px] truncate'>
                    <a href={`${doReqParams.data.origin}/${doReqParams.data.token}`} target='_blank' className='text-sky-600 underline hover:no-underline'>
                    {doReqParams.data.origin}/{doReqParams.data.token}
                    </a>
                </p>}

            </Popup>
            <MenuItem onClick={onClick}>
                Generate sign in link
            </MenuItem>
        </Fragment>
    )
}
